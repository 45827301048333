import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { CompanyStudentDevicesActions } from '../actions';
import {
  CompanyStudentDeviceDeleteRequestDTO,
  CompanyStudentDevicesListRequestDTO,
} from '../dto';
import { CompanyStudentDevicesService } from '../services';

@Injectable()
export class CompanyStudentDevicesEffects {
  companyStudentDeviceDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyStudentDevicesActions.companyStudentDeviceDelete),
      fetch({
        run: (action) => {
          return this.companyStudentDevicesService
            .companyStudentDeviceDelete(
              new CompanyStudentDeviceDeleteRequestDTO({
                companyName:
                  action.companyStudentDeviceDeleteVO.value.companyName,
                deviceId: action.companyStudentDeviceDeleteVO.value.deviceId,
              })
            )
            .pipe(
              map(() =>
                CompanyStudentDevicesActions.companyStudentDeviceDeleteSuccess({
                  companyStudentDeviceDeleteVO:
                    action.companyStudentDeviceDeleteVO,
                })
              )
            );
        },
        onError: (action, error) =>
          CompanyStudentDevicesActions.companyStudentDeviceDeleteFailure({
            error: error.message,
          }),
      })
    )
  );

  companyStudentDevicesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyStudentDevicesActions.companyStudentDevicesList),
      fetch({
        run: (action) => {
          return this.companyStudentDevicesService
            .companyStudentDevicesList(
              new CompanyStudentDevicesListRequestDTO({
                companyName:
                  action.companyStudentDevicesListVO.value.companyName,
              })
            )
            .pipe(
              map((_) =>
                CompanyStudentDevicesActions.companyStudentDevicesListSuccess({
                  devices: _.data.devices,
                })
              )
            );
        },
        onError: (action, error) =>
          CompanyStudentDevicesActions.companyStudentDevicesListFailure({
            error: error.message,
          }),
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly companyStudentDevicesService: CompanyStudentDevicesService
  ) {}
}
