import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  CompanyHostDeviceDeleteVO,
  CompanyHostDeviceDisableVO,
  CompanyHostDeviceEnableVO,
  CompanyHostDevicesListVO,
} from '@edgvr-front/companies/domain';
import { CompanyHostDevicesActions } from '../actions';
import { CompanyHostDevicesSelectors } from '../selectors';

@Injectable()
export class CompanyHostDevicesFacade {
  companyHostDevices$ = this.store.pipe(
    select(CompanyHostDevicesSelectors.companyHostDevices)
  );
  companyHostDevicesIsLoading$ = this.store.pipe(
    select(CompanyHostDevicesSelectors.isLoading)
  );

  constructor(private readonly store: Store) {}

  companyHostDeviceDelete(
    companyHostDeviceDeleteVO: CompanyHostDeviceDeleteVO
  ) {
    this.store.dispatch(
      CompanyHostDevicesActions.companyHostDeviceDelete({
        companyHostDeviceDeleteVO,
      })
    );
  }

  companyHostDeviceDisable(
    companyHostDeviceDisableVO: CompanyHostDeviceDisableVO
  ) {
    this.store.dispatch(
      CompanyHostDevicesActions.companyHostDeviceDisable({
        companyHostDeviceDisableVO,
      })
    );
  }

  companyHostDeviceEnable(
    companyHostDeviceEnableVO: CompanyHostDeviceEnableVO
  ) {
    this.store.dispatch(
      CompanyHostDevicesActions.companyHostDeviceEnable({
        companyHostDeviceEnableVO,
      })
    );
  }

  companyHostDevicesList(companyHostDevicesListVO: CompanyHostDevicesListVO) {
    this.store.dispatch(
      CompanyHostDevicesActions.companyHostDevicesList({
        companyHostDevicesListVO,
      })
    );
  }
}
