import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CompanyUsageGenerate2Cmd } from '@edgvr-front/companies/domain';
import { CompanyUsageActions } from '../actions';
import { CompanyUsageSelectors } from '../selectors';

@Injectable()
export class CompanyUsageFacade {
  usage$ = this.store.pipe(select(CompanyUsageSelectors.usage));
  isLoading$ = this.store.pipe(select(CompanyUsageSelectors.isLoading));

  constructor(private readonly store: Store) {}

  companyUsageClean() {
    this.store.dispatch(CompanyUsageActions.companyUsageClean());
  }

  companyUsageGenerate(companyUsageGenerateCmd: CompanyUsageGenerate2Cmd) {
    this.store.dispatch(
      CompanyUsageActions.companyUsageGenerate({
        companyUsageGenerateCmd,
      })
    );
  }
}
