import { Injectable } from '@angular/core';
import {
  CompanyStudentDeviceDeleteVO,
  CompanyStudentDevicesListVO,
} from '@edgvr-front/companies/domain';
import { select, Store } from '@ngrx/store';
import { CompanyStudentDevicesActions } from '../actions';
import { CompanyStudentDevicesSelectors } from '../selectors';

@Injectable()
export class CompanyStudentDevicesFacade {
  companyStudentDevices$ = this.store.pipe(
    select(CompanyStudentDevicesSelectors.companyStudentDevices)
  );
  companyStudentDevicesIsLoading$ = this.store.pipe(
    select(CompanyStudentDevicesSelectors.isLoading)
  );

  constructor(private readonly store: Store) {}

  companyStudentDeviceDelete(
    companyStudentDeviceDeleteVO: CompanyStudentDeviceDeleteVO
  ) {
    this.store.dispatch(
      CompanyStudentDevicesActions.companyStudentDeviceDelete({
        companyStudentDeviceDeleteVO,
      })
    );
  }

  companyStudentDevicesList(
    companyStudentDevicesListVO: CompanyStudentDevicesListVO
  ) {
    this.store.dispatch(
      CompanyStudentDevicesActions.companyStudentDevicesList({
        companyStudentDevicesListVO,
      })
    );
  }
}
