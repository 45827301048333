import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  CompaniesEffects,
  CompanyAccountsEffects,
  CompanyHostDevicesEffects,
  CompanyStudentDevicesEffects,
  CompanyUsageEffects,
  CompanyEffects,
} from './+state/effects';
import {
  CompaniesFacade,
  CompanyAccountsFacade,
  CompanyHostDevicesFacade,
  CompanyStudentDevicesFacade,
  CompanyFacade,
  CompanyUsageFacade,
} from './+state/facades';
import {
  COMPANIES_FEATURE_KEY,
  COMPANY_ACCOUNTS_FEATURE_KEY,
  companiesReducer,
  companyAccountsReducer,
  COMPANY_FEATURE_KEY,
  companyReducer,
  COMPANY_HOST_DEVICES_FEATURE_KEY,
  companyHostDevicesReducer,
  COMPANY_STUDENT_DEVICES_FEATURE_KEY,
  companyStudentDevicesReducer,
  COMPANY_USAGE_FEATURE_KEY,
  companyUsageReducer,
} from './+state/reducers';
import {
  CompaniesService,
  CompanyAccountsService,
  CompanyHostDevicesService,
  CompanyStudentDevicesService,
  CompanyService,
  CompanyUsageService,
} from './+state/services';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(COMPANIES_FEATURE_KEY, companiesReducer),
    StoreModule.forFeature(
      COMPANY_ACCOUNTS_FEATURE_KEY,
      companyAccountsReducer
    ),
    StoreModule.forFeature(COMPANY_FEATURE_KEY, companyReducer),
    StoreModule.forFeature(
      COMPANY_HOST_DEVICES_FEATURE_KEY,
      companyHostDevicesReducer
    ),
    StoreModule.forFeature(
      COMPANY_STUDENT_DEVICES_FEATURE_KEY,
      companyStudentDevicesReducer
    ),
    StoreModule.forFeature(COMPANY_USAGE_FEATURE_KEY, companyUsageReducer),
    EffectsModule.forFeature([
      CompaniesEffects,
      CompanyAccountsEffects,
      CompanyHostDevicesEffects,
      CompanyStudentDevicesEffects,
      CompanyUsageEffects,
      CompanyEffects,
    ]),
  ],
  providers: [
    CompaniesFacade,
    CompanyAccountsFacade,
    CompanyHostDevicesFacade,
    CompanyStudentDevicesFacade,
    CompanyUsageFacade,
    CompanyFacade,
    CompaniesService,
    CompanyAccountsService,
    CompanyHostDevicesService,
    CompanyStudentDevicesService,
    CompanyUsageService,
    CompanyService,
  ],
})
export class CompaniesDataAccessModule {}
