import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TokenDataAccessModule } from '@edgvr-front/token/data-access';
import { ApiDataAccessModule } from '@edgvr-front/api/data-access';
import { AccountDataAccessModule } from '@edgvr-front/account/data-access';
import {
  ACCOUNT_ACCESS_REJECTED_URL,
  AccountFeatureAccountAccessModule,
} from '@edgvr-front/account/feature/account-access';
import { COMPANY_ACCESS_REJECTED_URL } from '@edgvr-front/companies/feature/company-access';
import { COMPANY_BASE_URL } from '@edgvr-front/companies/util/company-url';
import { EdgvrPortalFeatureEnvModule } from '@edgvr-front/edgvr-portal/feature/env';
import { NotificationsFeatureNotificationsModule } from '@edgvr-front/notifications/feature/notifications';
import { EdgvrPortalFeatureTemplateModule } from '@edgvr-front/edgvr-portal/feature/template';
import { SentryFeatureModule } from '@edgvr-front/sentry/feature';
import { VERSION } from '@edgvr-front/util/version';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../../../edgvr-portal/src/environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    AppRoutingModule,
    TokenDataAccessModule.forRoot({
      domain: environment.domain,
      tokenName: environment.tokenName,
    }),
    ApiDataAccessModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    AccountDataAccessModule,
    AccountFeatureAccountAccessModule,
    EdgvrPortalFeatureEnvModule.forRoot(environment),
    EdgvrPortalFeatureTemplateModule,
    NotificationsFeatureNotificationsModule,
    SentryFeatureModule.forRoot({
      dsn: environment.sentry.dsn,
      env: environment.sentry.env,
      version: VERSION.raw,
    }),
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      deps: [DOCUMENT],
      useFactory: (document: Document) => document.location.origin,
    },
    {
      provide: COMPANY_BASE_URL,
      useValue: environment.portalUrl,
    },
    {
      provide: ACCOUNT_ACCESS_REJECTED_URL,
      useValue: environment.frontUrl,
    },
    {
      provide: COMPANY_ACCESS_REJECTED_URL,
      useValue: environment.frontUrl,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
