import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '@edgvr-front/api/data-access';
import {
  CompanyStudentDeviceEntity,
  CompanyStudentDeviceEntityJson,
} from '@edgvr-front/companies/domain';
import { Observable } from 'rxjs';
import {
  CompanyStudentDeviceDeleteRequestDTO,
  CompanyStudentDevicesListRequestDTO,
  CompanyStudentDevicesListResponseDTO,
} from '../dto';

@Injectable()
export class CompanyStudentDevicesService {
  constructor(private readonly apiService: ApiService) {}

  companyStudentDeviceDelete(
    companyStudentDeviceDeleteRequestDTO: CompanyStudentDeviceDeleteRequestDTO
  ): Observable<void> {
    const { companyName, deviceId } = companyStudentDeviceDeleteRequestDTO.data;

    const params = new URLSearchParams();
    params.append('company', companyName);

    return this.apiService.delete<void>(
      `company/student-devices/${deviceId}?${params}`
    );
  }

  companyStudentDevicesList(
    companyStudentDevicesListRequestDTO: CompanyStudentDevicesListRequestDTO
  ): Observable<CompanyStudentDevicesListResponseDTO> {
    const { companyName } = companyStudentDevicesListRequestDTO.data;

    const params = new URLSearchParams();
    params.append('company', companyName);

    return this.apiService
      .get<{ devices: CompanyStudentDeviceEntityJson[] }>(
        `company/student-devices?${params}`
      )
      .pipe(
        map(
          (_) =>
            new CompanyStudentDevicesListResponseDTO({
              devices: _.devices.map((_) => new CompanyStudentDeviceEntity(_)),
            })
        )
      );
  }
}
