import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { CompanyUsageActions } from '../actions';
import { CompanyUsageGenerateRequestDTO } from '../dto';
import { CompanyUsageService } from '../services';

@Injectable()
export class CompanyUsageEffects {
  companyUsageGenerate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyUsageActions.companyUsageGenerate),
      fetch({
        run: (action) => {
          return this.companyUsageService
            .companyUsageGenerate(
              new CompanyUsageGenerateRequestDTO({
                companyName: action.companyUsageGenerateCmd.value.companyName,
                dateFrom:
                  action.companyUsageGenerateCmd.value.companyUsageGenerateCmd
                    .value.dateFrom,
                dateTill:
                  action.companyUsageGenerateCmd.value.companyUsageGenerateCmd
                    .value.dateTill,
                workshopName:
                  action.companyUsageGenerateCmd.value.companyUsageGenerateCmd
                    .value.workshopName,
                companyHostDeviceSid:
                  action.companyUsageGenerateCmd.value.companyUsageGenerateCmd
                    .value.companyHostDeviceSid,
              })
            )
            .pipe(
              map((_) =>
                CompanyUsageActions.companyUsageGenerateSuccess({
                  companyGeneratedUsageVO: _.value.companyGeneratedUsageVO,
                })
              )
            );
        },
        onError: (_action, error) =>
          CompanyUsageActions.companyUsageGenerateFailure({
            error: error.message,
          }),
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly companyUsageService: CompanyUsageService
  ) {}
}
