import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '@edgvr-front/api/data-access';
import {
  CompanyGeneratedUsageJson,
  CompanyGeneratedUsageVO,
} from '@edgvr-front/companies/domain';
import {
  CompanyUsageGenerateRequestDTO,
  CompanyUsageGenerateResponseDTO,
} from '../dto';

@Injectable()
export class CompanyUsageService {
  constructor(private readonly apiService: ApiService) {}

  companyUsageGenerate(
    companyUsageGenerateRequestDTO: CompanyUsageGenerateRequestDTO
  ) {
    const {
      dateFrom,
      dateTill,
      companyHostDeviceSid,
      workshopName,
      companyName,
    } = companyUsageGenerateRequestDTO.value;

    const params = new URLSearchParams();
    if (companyName) {
      params.append('company', companyName);
    }

    return this.apiService
      .post<CompanyGeneratedUsageJson>(`company/usage/generate?${params}`, {
        dateFrom,
        dateTill,
        companyHostDeviceSid,
        workshopName,
      })
      .pipe(
        map(
          (_) =>
            new CompanyUsageGenerateResponseDTO({
              companyGeneratedUsageVO: new CompanyGeneratedUsageVO(_),
            })
        )
      );
  }
}
