import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { CompanyHostDevicesActions } from '../actions';
import {
  CompanyHostDeviceDeleteRequestDTO,
  CompanyHostDeviceDisableRequestDTO,
  CompanyHostDeviceEnableRequestDTO,
  CompanyHostDevicesListRequestDTO,
} from '../dto';
import { CompanyHostDevicesService } from '../services';

@Injectable()
export class CompanyHostDevicesEffects {
  companyHostDeviceDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyHostDevicesActions.companyHostDeviceDelete),
      fetch({
        run: (action) => {
          return this.companyHostDevicesService
            .companyHostDeviceDelete(
              new CompanyHostDeviceDeleteRequestDTO({
                companyName: action.companyHostDeviceDeleteVO.value.companyName,
                deviceId: action.companyHostDeviceDeleteVO.value.deviceId,
              })
            )
            .pipe(
              map(() =>
                CompanyHostDevicesActions.companyHostDeviceDeleteSuccess({
                  companyHostDeviceDeleteVO: action.companyHostDeviceDeleteVO,
                })
              )
            );
        },
        onError: (action, error) =>
          CompanyHostDevicesActions.companyHostDeviceDeleteFailure({
            error: error.message,
          }),
      })
    )
  );

  companyHostDeviceDisable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyHostDevicesActions.companyHostDeviceDisable),
      fetch({
        run: (action) => {
          return this.companyHostDevicesService
            .companyHostDeviceDisable(
              new CompanyHostDeviceDisableRequestDTO({
                companyName:
                  action.companyHostDeviceDisableVO.value.companyName,
                deviceId: action.companyHostDeviceDisableVO.value.deviceId,
              })
            )
            .pipe(
              map((_) =>
                CompanyHostDevicesActions.companyHostDeviceDisableSuccess({
                  device: _.data.device,
                })
              )
            );
        },
        onError: (action, error) =>
          CompanyHostDevicesActions.companyHostDeviceDisableFailure({
            error: error.message,
          }),
      })
    )
  );

  companyHostDeviceEnable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyHostDevicesActions.companyHostDeviceEnable),
      fetch({
        run: (action) => {
          return this.companyHostDevicesService
            .companyHostDeviceEnable(
              new CompanyHostDeviceEnableRequestDTO({
                companyName: action.companyHostDeviceEnableVO.value.companyName,
                deviceId: action.companyHostDeviceEnableVO.value.deviceId,
              })
            )
            .pipe(
              map((_) =>
                CompanyHostDevicesActions.companyHostDeviceEnableSuccess({
                  device: _.data.device,
                })
              )
            );
        },
        onError: (action, error) =>
          CompanyHostDevicesActions.companyHostDeviceEnableFailure({
            error: error.message,
          }),
      })
    )
  );

  companyHostDevicesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyHostDevicesActions.companyHostDevicesList),
      fetch({
        run: (action) => {
          return this.companyHostDevicesService
            .companyHostDevicesList(
              new CompanyHostDevicesListRequestDTO({
                companyName: action.companyHostDevicesListVO.value.companyName,
              })
            )
            .pipe(
              map((_) =>
                CompanyHostDevicesActions.companyHostDevicesListSuccess({
                  devices: _.data.devices,
                })
              )
            );
        },
        onError: (action, error) =>
          CompanyHostDevicesActions.companyHostDevicesListFailure({
            error: error.message,
          }),
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly companyHostDevicesService: CompanyHostDevicesService
  ) {}
}
