import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '@edgvr-front/api/data-access';
import {
  CompanyHostDeviceEntity,
  CompanyHostDeviceEntityJson,
} from '@edgvr-front/companies/domain';
import { Observable } from 'rxjs';
import {
  CompanyHostDeviceDeleteRequestDTO,
  CompanyHostDeviceDisableRequestDTO,
  CompanyHostDeviceDisableResponseDTO,
  CompanyHostDeviceEnableRequestDTO,
  CompanyHostDeviceEnableResponseDTO,
  CompanyHostDevicesListRequestDTO,
  CompanyHostDevicesListResponseDTO,
} from '../dto';

@Injectable()
export class CompanyHostDevicesService {
  constructor(private readonly apiService: ApiService) {}

  companyHostDeviceDelete(
    companyHostDeviceDeleteRequestDTO: CompanyHostDeviceDeleteRequestDTO
  ): Observable<void> {
    const { companyName, deviceId } = companyHostDeviceDeleteRequestDTO.data;

    const params = new URLSearchParams();
    params.append('company', companyName);

    return this.apiService.delete<void>(
      `company/host-devices/${deviceId}?${params}`
    );
  }

  companyHostDeviceDisable(
    companyHostDeviceDisableRequestDTO: CompanyHostDeviceDisableRequestDTO
  ): Observable<CompanyHostDeviceDisableResponseDTO> {
    const { companyName, deviceId } = companyHostDeviceDisableRequestDTO.data;

    const params = new URLSearchParams();
    params.append('company', companyName);

    return this.apiService
      .delete<{ device: CompanyHostDeviceEntityJson }>(
        `company/host-devices/${deviceId}/enable?${params}`
      )
      .pipe(
        map(
          (_) =>
            new CompanyHostDeviceDisableResponseDTO({
              device: new CompanyHostDeviceEntity(_.device),
            })
        )
      );
  }

  companyHostDeviceEnable(
    companyHostDeviceEnableRequestDTO: CompanyHostDeviceEnableRequestDTO
  ): Observable<CompanyHostDeviceEnableResponseDTO> {
    const { companyName, deviceId } = companyHostDeviceEnableRequestDTO.data;

    const params = new URLSearchParams();
    params.append('company', companyName);

    return this.apiService
      .post<{ device: CompanyHostDeviceEntityJson }>(
        `company/host-devices/${deviceId}/enable?${params}`,
        {}
      )
      .pipe(
        map(
          (_) =>
            new CompanyHostDeviceEnableResponseDTO({
              device: new CompanyHostDeviceEntity(_.device),
            })
        )
      );
  }

  companyHostDevicesList(
    companyHostDevicesListRequestDTO: CompanyHostDevicesListRequestDTO
  ): Observable<CompanyHostDevicesListResponseDTO> {
    const { companyName } = companyHostDevicesListRequestDTO.data;

    const params = new URLSearchParams();
    params.append('company', companyName);

    return this.apiService
      .get<{ devices: CompanyHostDeviceEntityJson[] }>(
        `company/host-devices?${params}`
      )
      .pipe(
        map(
          (_) =>
            new CompanyHostDevicesListResponseDTO({
              devices: _.devices.map((_) => new CompanyHostDeviceEntity(_)),
            })
        )
      );
  }
}
